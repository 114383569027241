import HmacSHA1 from 'crypto-js/hmac-sha1'
import Base64 from 'crypto-js/enc-base64'
import { defineAsyncComponent } from 'vue'

const server = import.meta.env.VITE_THUMBOR_SERVER || ''
const secret = import.meta.env.VITE_THUMBOR_SECRETKEY || ''

class ThumborService {
	#img = ''
	#dimensions = false
	#filters = ''
	#smart = false

	#trim = false
	#manual = false
	#fitIn = false

	#valign = false
	#halign = false

	constructor(img) {
		// Questionmarks must be replaced!
		// if (typeof img === 'string') img.replaceAll('?', '%3F')
		if (typeof img === 'string') img.replace(/\?/g, '%3F')

		this.#img = img
		return this
	}

	crop(halign = 'center', valign = 'middle') {
		this.halign(halign)
		this.valign(valign)
		return this
	}

	trim(orientation = false) {
		// orientation = 'top-left' | 'bottom-right'
		this.#trim = orientation ? `trim:${orientation}/` : 'trim/'
		return this
	}

	manual(a = false, b = false, c = false, d = false) {
		if (!a && !b && !c && !d) {
			return this
		}

		this.#manual = `${a}x${b}:${c}x${d}/`
		return this
	}

	fitIn(fitting = false) {
		// orientation = 'adaptive' | 'full'
		this.#fitIn = fitting ? `${fitting}-fit-in/` : 'fit-in/'
		return this
	}

	halign(value = 'center') {
		this.#halign = value
		return this
	}

	valign(value = 'middle') {
		this.#valign = value
		return this
	}

	filters(value) {
		this.#filters = value
		return this
	}

	cropPreset(preset = 'crop-center') {
		// Due to previous usage of Glide this must be converted

		const crop = preset.split('-')

		switch (preset) {
			case 'crop-top-left':
			case 'crop-top-right':
			case 'crop-bottom-left':
			case 'crop-bottom-right':
				if (crop[1]) this.valign(crop[1])
				if (crop[2]) this.halign(crop[2])
				break
			case 'crop-top':
			case 'crop-bottom':
				if (crop[1]) this.valign(crop[1])
				break
			case 'crop-left':
			case 'crop-right':
				if (crop[1]) this.halign(crop[1])
				break
			case 'crop-center': // = default
			case 'crop': // = the same as crop-center
			default:
				this.halign()
				this.valign()
		}

		return this
	}

	smart() {
		this.#smart = true
		return this
	}

	dimension(width, height) {
		this.#dimensions = [width, height]
		return this
	}

	url() {
		// Get info
		const info = /^.+\.([^.]+)$/.exec(this.#img)

		// Extention check, don't handle GIF!
		if (info && ['gif'].includes(info[1].toLowerCase())) return this.#img

		// Build url, order matters!
		let url = ''

		if (this.#trim) {
			url += this.#trim
		}

		if (this.#manual) {
			url += this.#manual
		}

		if (this.#fitIn) {
			url += this.#fitIn
		}

		if (this.#dimensions) {
			const [width, height] = this.#dimensions
			url += `${width}x${height}/`
		}

		if (this.#halign) {
			url += `${this.#halign}/`
		}

		if (this.#valign) {
			url += `${this.#valign}/`
		}

		if (this.#smart) {
			url += `smart/`
		}

		if (this.#filters) {
			url += `filters:${this.#filters}/`
		}

		url += this.#img

		const hash = Base64.stringify(HmacSHA1(url, secret)).replace(new RegExp('\\+', 'g'), '-').replace(new RegExp('\\/', 'g'), '_')

		return `${server}/${hash}/${url}`
	}
}

const thumbor = img => {
	return new ThumborService(img)
}

const ThumborVue = {
	install(app, options) {
		app.config.globalProperties.$thumbor = thumbor

		app.component(
			'ThumborImage',
			defineAsyncComponent(() => import('./ThumborImage.vue'))
		)
	},
}

export { thumbor, ThumborVue }
