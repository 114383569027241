<script setup>
import { uuid } from '@/helpers'
import { Link } from '@inertiajs/vue3'

const props = defineProps({
	items: {
		type: Array,
		default: () => [],
	},
	tag: {
		type: String,
		default: 'div',
	},
	columns: {
		type: Object,
		default: () => {
			return {
				xs: 12,
				sm: 6,
				md: 4,
				lg: 3,
			}
		},
	},
	headerColumns: {
		type: Object,
		default: () => {},
	},
	title: {
		type: String,
		default: '',
	},
	titleClasses: {
		type: String,
		default: 'fw-bold fs-4 mb-3',
	},
	url: {
		type: String,
		default: '',
	},
	linkText: {
		type: String,
		default: 'Show all',
	},
	linkTarget: {
		type: String,
		default: '',
	},
	inline: {
		type: Boolean,
		default: false,
	},
	equalSlideHeight: {
		type: Boolean,
		default: true,
	},
	calculatorWrapperClasses: {
		type: String,
		default: 'row',
	},
	headerWrapperClasses: {
		type: String,
		default: 'row',
	},
	cssMode: {
		type: Boolean,
		default: false,
	},
	mobileArrows: {
		type: Boolean,
		default: false,
	},
})

const id = uuid('swiper-')
const uniqueKey = ref(uuid())
const appStore = useAppStore()

const space = ref(false)
const calculator = ref(false)
const headerSpace = ref(false)
const header = ref(false)

const slideWidth = computed(() => {
	return appStore.isMobile ? `${space.value.width - 15}px` : `${space.value.width - 24}px`
})

const calculatorClasses = computed(() => {
	let classes = ''

	Object.entries(props.columns).map(breakpoint => {
		classes += breakpoint[0] === 'xs' ? `col-${breakpoint[1]} ` : `col-${breakpoint[0]}-${breakpoint[1]} `
	})

	return classes
})

const headerClasses = computed(() => {
	if (!props.headerColumns) return calculatorClasses

	let classes = ''

	Object.entries(props.headerColumns).map(breakpoint => {
		classes += breakpoint[0] === 'xs' ? `col-${breakpoint[1]} ` : `col-${breakpoint[0]}-${breakpoint[1]} `
	})

	return classes
})

const enoughSlideItems = computed(() => {
	let show = false

	Object.entries(props.columns).map(breakpoint => {
		if (appStore.breakpoints[breakpoint[0]] <= appStore.getWindowWidth) {
			const available = 12 / breakpoint[1]
			show = props.items.length > available
		}
	})

	return show
})

const calcSpace = () => {
	space.value = calculator.value.getBoundingClientRect()
	headerSpace.value = header.value.getBoundingClientRect()
}

watch(
	() => space.value.x,
	(newVal, oldVal) => {
		// Changing the uniqueKey forces the swiper to rerender
		uniqueKey.value = uuid()
	}
)

onMounted(() => {
	calcSpace()
	// console.log(space.value)
	// nextTick(() => {
	// 	const swiper = document.getElementById(id)
	// 	if (isClient && swiper) {
	// 		swiper.initialize()
	// 		// console.dir(swiper)
	// 	}
	// })
})
</script>

<template>
	<component
		:is="tag"
		:key="uniqueKey"
		class="theme-base-swiper"
	>
		<div :class="[!inline ? 'container-xxl main-wrapper' : null]">
			<div :class="[calculatorWrapperClasses]">
				<div
					ref="calculator"
					:class="[calculatorClasses]"
				>
					<!-- <div class="bg-primary">X</div> -->
				</div>
			</div>
			<div :class="[headerWrapperClasses]">
				<div
					ref="header"
					:class="[headerClasses]"
				>
					<div class="d-flex justify-content-between">
						<component
							:is="title ? 'h4' : 'span'"
							:class="[title ? titleClasses : null]"
						>
							<div v-html="$t(title)"></div>
						</component>
						<div
							class="mb-3 flex-shrink-0"
							:style="{
								marginRight: enoughSlideItems && !inline && !appStore.isMobile ? `-${headerSpace.x - 12}px` : null,
							}"
						>
							<Link
								v-if="url && !linkTarget"
								:href="url"
								class="feature-link"
							>
								{{ $t(linkText) }}
							</Link>
							<a
								v-else-if="url && linkTarget"
								:href="url"
								:target="linkTarget"
								>{{ $t(linkText) }}</a
							>
							<span v-show="enoughSlideItems">
								<button
									class="swiper-button-prev-custom ms-3 me-1"
									:class="[!mobileArrows ? 'd-none d-md-inline-block' : null]"
									:data-items-prev="id"
								></button>
								<button
									class="swiper-button-next-custom"
									:class="[!mobileArrows ? 'd-none d-md-inline-block' : null]"
									:data-items-next="id"
								></button>
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- <div
			v-if="!enoughSlideItems"
			:class="[!inline ? 'container-xxl main-wrapper' : null]"
		>
			<div class="row">
				<div
					v-for="(item, itemIndex) in items"
					:key="`${id}column-${itemIndex}`"
					:class="[calculatorClasses]"
				>
					<slot :item="item"></slot>
				</div>
			</div>
		</div> -->

		<swiper-container
			v-if="items.length"
			:id="id"
			events-prefix="swiper-"
			:space-between="15"
			slides-per-view="auto"
			:slides-offset-before="24"
			:slides-offset-after="24"
			:mousewheel="
				!enoughSlideItems
					? false
					: {
							forceToAxis: true,
					  }
			"
			:navigation="{
				nextEl: `[data-items-next='${id}']`,
				prevEl: `[data-items-prev='${id}']`,
			}"
			:autoplay="false"
			:free-mode="true"
			:free-mode-sticky="true"
			:css-mode="cssMode"
			:breakpoints="{
				[appStore.breakpoints.md]: {
					slidesOffsetBefore: !inline ? headerSpace.x + 12 : 0,
					slidesOffsetAfter: !inline ? 24 : 0,
					spaceBetween: 24,
				},
			}"
			class="pb-1"
			:class="[inline ? 'mx-n4 mx-md-0' : null]"
			@swiper-resize="calcSpace()"
		>
			<swiper-slide
				v-for="(item, itemIndex) in items"
				:key="`${id}slide-${itemIndex}`"
				:class="[equalSlideHeight ? 'd-flex' : null]"
				:style="{
					maxWidth: slideWidth,
					height: equalSlideHeight ? 'auto' : null,
				}"
			>
				<slot :item="item"></slot>
			</swiper-slide>
		</swiper-container>
	</component>
</template>

<style lang="scss">
/* .theme-base-swiper {
	swiper-container[css-mode='true'] {
		padding-left: 40px;
	}
} */
</style>
