export const registerPluginDirectives = app => {
	app.directive('html-raw', {
		created(el, binding, vnode, prevVnode) {
			if (!binding.value) return

			// el.innerHTML = binding.value
			const contents = document.createRange().createContextualFragment(binding.value)

			el.appendChild(contents)
		},
	})
}
